<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <header class="heade">
      <div class="box">
        <div class="center_title">
          <img class="logo" @click="home" src="../assets/wai_daohang/logo_wai.png" alt>
          <ul class="cnt">
            <li ref='ding' v-for="(i,index) in list" :key='index' @click="dian_list(i)" :class="path == i.path?'bei':''">{{i.name}}</li>
          </ul>
          <div @click="dian_qiehuan_qiye" :class="datea==1?'right2':'right'">
            <p>{{qiye_name}}</p>
            <transition name="fade">
              <div class="qiye" v-if="qiye_list" @click.stop>
                <ul>
                  <li v-for="(i,index) in qiyes_list" :key='index' @click="dianji_qiehuana(i)">{{i.ent_name}}</li>
                </ul>
                <div @click="jin_tianjia">创建企业</div>
              </div>
            </transition>
          </div>
          <p class="tuichu" @click="tui_chu">退出</p>
        </div>
        <div class="tit" v-if="path=='/install'">
          <ul>
            <li ref="have" v-for="(i,index) in qishe" :key="index" @click="dian_sml_list(i)" :class="{'bei2':i.path == patha}">{{i.name}}</li>
          </ul>
        </div>
      </div>
    </header>
</template>

<script>
import { get_my_info, insert_m_login_log, query_form_manage_power_list, query_ent_sub_manager_list, query_user_info, logout, toggle_user_ent, get_rel_list } from '../api/api.js'
import { query_user_ent_list } from '../api/noapi.js'
export default {
  name: 'heade',
  data () {
    return {
      list: [
        {
          name: '主页面',
          path: '/home'
        },
        // {
        //   name:'数据管理',
        //   path:'/dbms',
        // },
        {
          name: '企业设置',
          path: '/install'
        },
        {
          name: '客户服务',
          path: '/home'
        },
        {
          name: '个人设置',
          path: '/me'
        }
      ],
      // list:['主页面','数据管理','企业设置','客户服务','个人设置'],
      qishe: [],
      jichuxinxi: '',
      qiye_name: '',
      datea: '',
      qiye_list: false,
      qiyes_list: [],
      qiye_dengji: '',
      geshu_num: '',
      ding_num: '',
      path: '',
      patha: ''
    }
  },
  created () {
    this.jichu()
    this.lujing(this.$route.path)
    if (sessionStorage.getItem('pc_mlbb_jichuxinxi') != null && sessionStorage.getItem('pc_mlbb_jichuxinxi') != undefined && sessionStorage.getItem('pc_mlbb_jichuxinxi') != '') {
      this.jichuxinxi = JSON.parse(JSON.parse(sessionStorage.getItem('pc_mlbb_jichuxinxi')))
      console.log(999999999, this.jichuxinxi)
    }
    // if(this.qiye_dengji!=1&&this.qiye_dengji.length!=0){
    //   this.$router.push('/install')
    //   sessionStorage.setItem("pc_pc_title", '0');
    // }
  },
  mounted () {
    // 不同的人 不同的权限         没看明白写了个啥
    // if(this.inde==2){
    //   for(let i=0;i<this.$refs.have.length;i++){
    //     if(this.$refs.have[i].className=='bei2'){
    //       this.geshu_num=i
    //     }
    //   }
    // }
    // // 不同的人 不同的权限
    // for(let i=0;i<this.$refs.ding.length;i++){
    //   if(this.$refs.ding[i].className=='bei'){
    //     this.ding_num=i
    //   }
    // }
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.lujing(val.fullPath)
      },
      // 深度观察监听
      deep: true
    }
  },
  methods: {
    lujing (path) {
      if (path == '/install' || path == '/primarylog' || path == '/sybadmin' || path == '/anadata' || path == '/approvalz' || path == '/systemyear' || path == '/control' || path == '/detsta' || path == '/role' || path == '/account' || path == '/warehouse' || path == '/product' || path == '/field' || path == '/importquery' || path == '/admlog' || path == '/subadmin' || path == '/target' || path == '/sales' || path == '/appsett' || path == '/modelallsale' || path == '/imports' || path == '/enttarsale' || path == '/enttarproduct' || path == '/enttarcount' || path == '/entsale' || path == '/prodjur' || path == '/anatarsale' || path == '/anatarproduct' || path == '/anatarcount' || path == '/anaproduct' || path == '/anasalemethod' || path == '/anasalebranch' || path == '/anasalealert' || path == '/anatarget' || path == '/quan' || path == '/enttarget' || path == '/modelall' || path == '/anasalekanban' || path == '/worksettings' || path == '/appdaochu') {
        this.path = '/install'
      } else if (path == '/saleb' || path == '/targetb' || path == '/productb' || path == '/accountingb' || path == '/managementb' || path == '/growupb') {
        this.path = '/dbms'
      } else if (path == '/personset' || path == '/myncb' || path == '/mypwd' || path == '/mysuggestion' || path == '/quit') {
        this.path = '/me'
      } else {
        this.path = path
      }
      console.log(path)
      if (path == '/systemyear' || path == '/detsta' || path == '/control' || path == '/role' || path == '/account' || path == '/product' || path == '/warehouse' || path == '/field' || path == '/importquery' || path == '/admlog' || path == '/imports' || path == '/worksettings') {
        this.patha = '/basicdata'
      } else if (path == '/anatarget' || path == '/anatarsale' || path == '/anatarproduct' || path == '/anatarcount' || path == '/anasalemethod' || path == '/anaproduct' || path == '/anasalebranch' || path == '/anasalealert' || path == '/anasalekanban') {
        this.patha = '/anadata'
      } else if (path == '/appsett' || path == '/appdaochu' || path == '/modelallsale') {
        this.patha = '/approvalz'
      } else if (path == '/enttarsale' || path == '/enttarproduct' || path == '/enttarcount' || path == '/entsale' || path == '/quan' || path == '/enttarget' || path == '/modelall' || path == '/prodjur') {
        this.patha = '/quan'
      } else {
        this.patha = path
      }
    },
    // 基础信息
    jichu () {
      if (sessionStorage.getItem('pc_mlbb_ent_flag') != null && sessionStorage.getItem('pc_mlbb_ent_flag') != undefined && sessionStorage.getItem('pc_mlbb_ent_flag') != '') {
        this.datea = JSON.parse(sessionStorage.getItem('pc_mlbb_ent_flag'))
        console.log(this.datea)
      }
      
      if (this.datea == 1) {
        this.qiye_name = JSON.parse(sessionStorage.getItem('pc_mlbb_ent_info')).ent_name
        console.log(this.qiye_name)
        this.qiye_dengji = JSON.parse(sessionStorage.getItem('pc_mlbb_ent_info')).staff_level
        console.log(this.qiye_dengji)
        console.log(JSON.parse(sessionStorage.getItem('pc_mlbb_ent_info')))
        // this.qiye_name=JSON.parse(sessionStorage.getItem("pc_mlbb_ent_info"))
        // this.qiye_dengji=JSON.parse(sessionStorage.getItem("pc_mlbb_ent_info"))
        query_user_info({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            if (res.data.body.staff_level == 1) {
              this.qishe = [
                {
                  name: '企业认证',
                  path: '/install'
                },
                {
                  name: '主管理员设置',
                  path: '/primarylog'
                },
                {
                  name: '基础信息设置',
                  path: '/basicdata'
                },
                {
                  name: '子管理员设置',
                  path: '/subadmin'
                },
                {
                  name: '分析数据设置',
                  path: '/anadata'
                },
                {
                  name: '权限设置',
                  path: '/quan'
                },
                {
                  name: '审批设置',
                  path: '/approvalz'
                }
              ]
            } else {
              query_ent_sub_manager_list({
                data: {
                  ent_id: this.$ent_id()
                }
              }).then(rbs => {
                console.log(rbs)
                if (rbs.data.code == 200) {
                  const data = JSON.parse(rbs.data.body.data)
                  console.log(data)
                  query_form_manage_power_list({
                    data: {
                      ent_id: this.$ent_id(),
                      user_id: this.$jichuxinxi().user_id
                    }
                  }).then(rcs => {
                    if (rcs.data.code == 200) {
                      const datd = JSON.parse(rcs.data.body.data)
                      console.log(datd)
                      let zi_guanli = false
                      data.forEach(it => {
                        if (it.staff_id == this.$jichuxinxi().user_id) {
                          zi_guanli = true
                        }
                      })
                      let shenpi = false
                      const shenpi_ren_list = []
                      // if(datd.length!=0){
                      //   shenpi=true
                      // }
                      datd.forEach(it => {
                        if (it.children.length != 0) {
                          shenpi_ren_list.push('1')
                        }
                      })
                      if (shenpi_ren_list.length != 0) {
                        shenpi = true
                      }
                      if (zi_guanli && shenpi) {
                        this.qishe = [
                          {
                            name: '企业认证',
                            path: '/install'
                          },
                          {
                            name: '主管理员设置',
                            path: '/primarylog'
                          },
                          {
                            name: '分析数据设置',
                            path: '/anadata'
                          },
                          {
                            name: '权限设置',
                            path: '/quan'
                          },
                          {
                            name: '审批设置',
                            path: '/approvalz'
                          }
                        ]
                      } else if (zi_guanli && !shenpi) {
                        this.qishe = [
                          {
                            name: '企业认证',
                            path: '/install'
                          },
                          {
                            name: '主管理员设置',
                            path: '/primarylog'
                          },
                          {
                            name: '分析数据设置',
                            path: '/anadata'
                          },
                          {
                            name: '权限设置',
                            path: '/quan'
                          }
                        ]
                      } else if (!zi_guanli && shenpi) {
                        this.qishe = [
                          {
                            name: '企业认证',
                            path: '/install'
                          },
                          {
                            name: '主管理员设置',
                            path: '/primarylog'
                          },
                          {
                            name: '审批设置',
                            path: '/approvalz'
                          }
                        ]
                      } else if (!zi_guanli && !shenpi) {
                        this.qishe = [
                          {
                            name: '企业认证',
                            path: '/install'
                          },
                          {
                            name: '主管理员设置',
                            path: '/primarylog'
                          }
                        ]
                      }
                      console.log(datd)
                    } else if (rcs.data.code == 500) {}
                  })
                  // this.tableData=date
                  // }
                } else if (rbs.data.code == 500) {}
              })
              // this.qishe=[
              //   {
              //     name:'企业认证',
              //     path:'/install',
              //   },
              //   {
              //     name:'主管理员设置',
              //     path:'/primarylog',
              //   },
              // ]
            }
          } else if (res.data.code == 500) {}
        })
        
        // this.qishe=['企业认证','主管理员设置','基础信息设置','子管理员设置','分析数据设置','审批设置']
      } else {
        this.qiye_name = '切换'
        this.qishe = [
          {
            name: '企业认证',
            path: '/install'
          },
          {
            name: '主管理员设置',
            path: '/primarylog'
          }
        ]
        // this.qishe=['企业认证','主管理员设置']
      }
    },
    // 点击切换企业或者企业
    dian_qiehuan_qiye () {
      this.qiye_list = !this.qiye_list
      query_user_ent_list({
        data: {
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10080) {
          console.log(JSON.parse(res.data.body.data))
          const date = JSON.parse(res.data.body.data)
          this.qiyes_list = date
          console.log('qiyes_list', date)
        } else if (res.data.code == 10081) {
        }
      })
    },
    home () {
      this.$router.push('home')
    },
    // 退出按钮
    tui_chu () {
      logout({
        data: {
          mobile: this.$jichuxinxi().mobile
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10015) {
          this.$message({
            message: '退出成功',
            type: 'success'
          })
          sessionStorage.clear()
          // sessionStorage.setItem("pc_pc_wai_daohang", JSON.stringify(0));
          this.$router.push('/')
        } else if (res.data.code == 10016) {
          this.$message({
            message: '退出失败',
            type: 'error'
          }) 
        } else if (res.data.code == 11000) {
          // this.$message({
          //     message: '您未登录',
          //     type: 'error'
          // }); 
          this.$message({
            message: '退出成功',
            type: 'success'
          })
          sessionStorage.clear()
          // sessionStorage.setItem("pc_pc_wai_daohang", JSON.stringify(0));
          this.$router.push('/')
        }
      })
    },
    // 点第一行
    dian_list (i) {
      this.path = i.path
      this.$router.push(i.path)
      // this.inde=index
      // if(this.inde==0){
      //   this.$router.push('/home')
      //   this.inde=this.ding_num
      // }else if(this.inde==1){
      //   this.$router.push('/dbms')
      // }else if(this.inde==2){
      //   this.$router.push('/install')
      //   sessionStorage.setItem("pc_pc_title", '0');
      // }else if(this.inde==3){
      //   this.$message({
      //     message: '正在开发中。。。',
      //     type: 'error'
      //   });
      //   this.inde=this.ding_num
      // }else if(this.inde==4){
      //   this.$message({
      //     message:'正在开发中。。。',
      //     type:'error'
      //   })
      //   this.inde=this.ding_num
      // }
      // sessionStorage.setItem("pc_pc_pc_shujuguanli", '0');
      // if(sessionStorage.getItem("pc_pc_pc_shujuguanli")!=0&&sessionStorage.getItem("pc_pc_pc_shujuguanli")!='undefined'&&sessionStorage.getItem("pc_pc_pc_shujuguanli")!=null){
      //   this.reflashPage()
      // }
      // sessionStorage.setItem("pc_pc_pc_title", JSON.stringify(this.inde));
    },
    // 点第二行
    dian_sml_list (i) {
      // console.log(i)
      // console.log(this.patha)
      // if(i.path!=this.patha){
      //   console.log(i.path)
      this.patha = i.path
      this.$router.push(i.path)
      // }
      // this.geshu=index
      // console.log(this.geshu)
      // if(this.geshu==0){
      //   this.$router.push('/install')
      // }else if(this.geshu==1){
      //   this.$router.push('/primarylog')
      // }else if(this.geshu==2){
      //   // if(this.qiye_dengji==0||this.qiye_dengji==2){
      //   //   this.$message({
      //   //     message: '暂无权限',
      //   //     type: 'error'
      //   //   });
      //   //   this.geshu=this.geshu_num
      //   // }else{
      //     this.$router.push('/basicdata')
      //   // }
      // }else if(this.geshu==3){
      //   // if(this.qiye_dengji==0){
      //   //   this.$message({
      //   //     message: '暂无权限',
      //   //     type: 'error'
      //   //   });
      //   //   this.geshu=this.geshu_num
      //   // }else if(this.qiye_dengji==2){
      //   //   this.$message({
      //   //     message: '暂无权限',
      //   //     type: 'error'
      //   //   });
      //   //   this.geshu=this.geshu_num
      //   // }else if(this.qiye_dengji==1){
      //     this.$router.push('/subadmin')
      //   // }
      // }else if(this.geshu==4){
      //   // if(this.qiye_dengji==0){
      //   //   this.$message({
      //   //     message: '暂无权限',
      //   //     type: 'error'
      //   //   });
      //   //   this.geshu=this.geshu_num
      //   // }else{
      //     this.$router.push('/anadata')
      //   // }
      // }else if(this.geshu==5){
      //   // if(this.qiye_dengji==0){
      //   //   this.$message({
      //   //     message: '暂无权限',
      //   //     type: 'error'
      //   //   });
      //   //   this.geshu=this.geshu_num
      //   // }else{
      //     this.$router.push('/approvalz')
      //   // }
      // }
      // sessionStorage.setItem("pc_pc_pc_zuzhi", JSON.stringify(0));
      // if(sessionStorage.getItem("pc_pc_pc_zuzhi")!=0&&sessionStorage.getItem("pc_pc_pc_zuzhi")!='undefined'&&sessionStorage.getItem("pc_pc_pc_zuzhi")!=null){
      //   this.reflashPage()
      // }
      
      // sessionStorage.setItem("pc_pc_pc_fenxi", JSON.stringify(0));
      // sessionStorage.setItem("pc_pc_title", JSON.stringify(this.geshu));
    },
    // 点击切换
    dianji_qiehuana (i) {
      if (i.use_status != 0) {
        if (i.node_flag != 0) {
          if (i.node_not_enough != 0) {
            toggle_user_ent({
              data: {
                ent_id: i.ent_id,
                user_id: this.jichuxinxi.user_id
              }
            }).then(res => {
              console.log(res)
              if (res.data.code == 10082) {
                this.$message({
                  message: '切换成功',
                  type: 'success'
                })
                get_my_info({
                  data: {
                    user_id: this.$jichuxinxi().user_id
                  }
                }).then(ras => {
                  if (ras.data.code == 10069) {
                    this.jichuxinxi = JSON.parse(ras.data.body.data)
                    sessionStorage.setItem('pc_mlbb_jichuxinxi', JSON.stringify(ras.data.body.data))
                    console.log(this.jichuxinxi)
                  }
                })
                get_rel_list({
                  data: {
                    user_id: this.jichuxinxi.user_id
                  }
                }).then(ras => {
                  if (ras.data.code == 10091) {
                    console.log(JSON.parse(ras.data.body.data))
                    const qiye = JSON.parse(ras.data.body.data)
                    sessionStorage.setItem('pc_mlbb_ent_flag', JSON.stringify(qiye.ent_flag))
                    sessionStorage.setItem('pc_mlbb_ent_info', JSON.stringify(qiye.staff_info))
                    sessionStorage.setItem('pc_mlbb_quanxian', JSON.stringify(qiye.rel_list))
                    insert_m_login_log({
                      data: {
                        ent_id: i.ent_id,
                        mobile: this.jichuxinxi.mobile
                      }
                    }).then(rzhi => {
                      this.jichu()
                      // if(this.qiye_dengji!=1&&this.qiye_dengji.length!=0){
                      this.$router.push('/home')
                      //   sessionStorage.setItem("pc_pc_title", '0');
                      // }
                      this.reflashPage()
                      this.qiye_list = false
                    })
                  } else if (ras.data.code == 10092) {}
                })
              } else if (res.data.code == 10083) {
                this.$message({
                  message: '切换失败',
                  type: 'error'
                })
              } else if (res.data.code == 11700) {
                this.$message({
                  message: '您没有该企业的登录权限，请联系主管理员添加',
                  type: 'error'
                })
              }
            })
          } else {
            this.$message({
              message: '当前登录节点少于付费节点数，请联系主管理员进行设置',
              type: 'error'
            })
          }
        } else {
          this.$message({
            message: '您没有该企业的登录权限，请联系主管理员添加',
            type: 'error'
          })
        }
      } else {
        this.$message({
          message: '该企业暂不能使用，请联系官方客服',
          type: 'error'
        })
      }
    },
    // 相当于刷新当前页面
    reflashPage () {
      const NewPage = '_empty' + '?time=' + new Date().getTime() / 500
      this.$router.push(NewPage)
      this.$router.go(-1)
    },
    // 进添加企业
    jin_tianjia () {
      this.$router.push('/certification')
      sessionStorage.setItem('pc_bianji_ing', JSON.stringify('0'))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.heade {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fff;
  z-index: 99;
  .box{
    .center_title {
      height: 0.8rem;
      margin: 0 auto;
      width:12rem;
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // .header_left {
      //   display: flex;
      //   align-items: center;
        .logo {
          height: 0.5rem;
          cursor:pointer;
        }
      //   .text {
      //     height: 0.18rem;
      //     width: 0.75rem;
      //     margin-left: 0.05rem;
      //     margin-right: 0.23rem;
      //   }
      //   .phone {
      //     height: 0.18rem;
      //     width: 0.17rem;
      //     margin-right: 0.05rem;
      //   }
      //   .phone_text {
      //     font-size: 0.14rem;
      //     color: #3c3a3e;
      //   }
      // }
      .cnt{
        display: flex;
        justify-content: space-between;
        align-items: center;
        li{
          height:0.8rem;
          width: 0.8rem;
          color:#1A2533;
          font-size: 0.16rem;
          line-height: 0.8rem;
          text-align: center;
          margin: 0 0.3rem;
          font-weight: 400;
          cursor:pointer;
        }
        .bei{
          color:#8d52e3;
          border-bottom: 0.02rem solid #8d52e3;
        }
      }
      .right,.right2{
        margin-right: -0.8rem;
        height:0.8rem;
        line-height: 0.8rem;
        color:#fff;
        padding: 0 0.32rem;
        font-size: 0.18rem;
        position: relative;
        cursor:pointer;
        .qiye{
          min-height:2.85rem;
          width:2.37rem;
          background-color: #fff;
          position: absolute;
          bottom: -2.85rem;
          left: -0.58rem;
          box-shadow:0 0.02rem 0.04rem 0 rgba(0,0,0,0.36);
          z-index: 10;
          ul{
            margin:0.1rem 0;
            height:2rem;
            overflow-y:auto;
            &::-webkit-scrollbar {
                width: 20px;
                height: 8px;
            }
            
            &::-webkit-scrollbar-thumb {
                border-radius: 12px;
                border: 6px solid rgba(0, 0, 0, 0);
                box-shadow: 8px 0 0 #A5ADB7 inset;
            }
            
            &::-webkit-scrollbar-thumb:hover {
                box-shadow: 8px 0 0 #4A4A4A inset;
            }
            li{
              width:1.96rem;
              margin: 0 auto;
              line-height: 0.15rem;
              z-index: 11;
              color:#4C4A4D;
              font-size: 0.14rem;
              padding: 0.1rem 0;
              &:hover{
                background: #eee;
              }
            }
          }
          div{
            background: #9a86db;
            height:0.32rem;
            width:1.66rem;
            line-height: 0.32rem;
            text-align: center;
            font-size: 0.14rem;
            color:#fff;
            border-radius: 0.02rem;
            position: absolute;
            bottom: 0.2rem;
            left:50%;
            margin-left: -0.83rem;
          }
        }
        .fade-enter-active, .fade-leave-active {
          transition: opacity 1s
        }
        .fade-enter, .fade-leave-active {
          opacity: 0
        }
      }
      .right{
        background-color: #9a86db;
        color: #fff;
      }
      .right2{
        background: #fff;
        color:#1A2533;
        font-size: 0.14rem;
      }
      .tuichu{
        color:#1A2533;
        font-size: 0.14rem;
        cursor:pointer;
        // width:0.32rem;
        margin-left: 0.4rem;
        z-index: 99999;
      }
    }
    .tit{
      height:0.42rem;
      width:100%;
      background:#F8F8F8;
      box-shadow:0 0.04rem 0.05rem 0 rgba(0,0,0,0.18);
      ul{
        height: 0.42rem;
        width:10rem;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 1rem;
        li{
          height:100%;
          color:#1A2533;
          font-size: 0.12rem;
          line-height: 0.42rem;
          cursor:pointer;
        }
        .aaa{
          &:nth-child(4){
            display: none;
          }
        }
        .bei2{
          color:#8d52e3;
        }
      }
    }
  }
}
</style>
